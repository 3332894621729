import React from "react"

export default () => (
   <svg
      viewBox = "0 0 60 60"
      version="1.1"
      id="Layer_2_1_"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      style={{
         width: "1.5rem",
         height: "1.5rem"
      }}
   >
      <g transform="matrix(1.33817,0,0,1.30172,-7.9775,-7.09314)">
         <path d = "M47.061,36.661C47.059,36.659 47.058,36.658 47.056,36.656C47.122,36.432 47.19,36.21 47.247,35.981C49.712,26.16 43.696,14.549 33.516,8.435C37.977,14.483 39.95,21.809 38.197,28.215C38.041,28.786 37.853,29.335 37.645,29.868C37.42,29.72 37.136,29.552 36.754,29.341C36.754,29.341 26.628,23.089 15.652,12.029C15.364,11.739 21.504,20.806 28.474,28.169C25.19,26.326 16.04,19.669 10.247,14.367C10.959,15.554 11.805,16.696 12.736,17.797C17.573,23.932 23.882,31.501 31.44,37.314C26.13,40.564 18.626,40.816 11.155,37.317C9.307,36.451 7.57,35.406 5.962,34.219C9.124,39.277 13.995,43.642 19.923,46.19C26.992,49.228 34.022,49.022 39.258,46.24L39.254,46.246C39.278,46.23 39.309,46.214 39.333,46.199C39.548,46.083 39.762,45.965 39.97,45.841C42.486,44.535 47.455,43.21 50.122,48.4C50.776,49.67 52.164,42.94 47.061,36.661Z"
         fill = "#F05138" />
      </g>
   </svg>
)

